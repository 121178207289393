import $ from "cash-dom";
import app from "app";
import {lineLimit, getViewport, cloneRect} from "app/common/utils.mjs";

export function initImgLazyLoad () {
	const images = window.document.querySelectorAll("source[data-srcset], img[data-src] , img[data-srcset]");

	const config = {
		rootMargin: "1000px 0px",
		threshold: 0.01,
	};
	let observer;

	images.forEach(image => {
		const picture = image.closest("picture") || image;
		const intersectTarget = picture.dataset.intersect ? document.querySelector(intersectTarget) : picture;
		observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.intersectionRatio > 0) {
					// Stop watching and load the image
					observer.unobserve(entry.target);
					// call our method: preloadImage
					preloadImage(image);
				}
			});
		}, config);
		observer.observe(intersectTarget);
	});
	const preloadImage = (element) => {
		if (element.dataset && element.dataset.src) {
			element.src = element.dataset.src;
		}
		if (element.dataset && element.dataset.srcset) {
			element.srcset = element.dataset.srcset;
		}
	};
}
export function initScrollView () {
	const odt = 0;
	const attrIn = "in-view";
	const attrNotIn = "not-in-view";
	const attrHiddenNotIn = "hidden-not-in-view";

	const intersectionObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const el = entry.target;
			const isIntersecting = entry.isIntersecting;
			if (el.hasAttribute(attrIn)) {
				const classNames = el.getAttribute(attrIn).split(" ");
				let target = el.getAttribute("target-view");
				target = $(target);
				target = target[0] ? target : $(el);
				classNames.forEach(className => {
					if (isIntersecting) {
						if (className.startsWith("-")) {
							target.removeClass(className.substring(1));
						}
						else {
							target.addClass(className);
						}
						const callbackStr = el.getAttribute(`on-${attrIn}`);
						callbackStr && (new Function(callbackStr))();
					}
				});
			}
			if (el.hasAttribute(attrNotIn)) {
				const classNames = el.getAttribute(attrNotIn).split(" ");
				let target = el.getAttribute("target-view");
				target = $(target);
				target = target[0] ? target : $(el);
				classNames.forEach(className => {
					if (!isIntersecting) {
						if (className.startsWith("-")) {
							target.removeClass(className.substring(1));
						}
						else {
							target.addClass(className);
						}
						const callbackStr = el.getAttribute(`on-${attrNotIn}`);
						callbackStr && (new Function(callbackStr))();
					}
				});
			}
			if (el.hasAttribute(attrHiddenNotIn)) {
				const classNames = el.getAttribute(attrHiddenNotIn).split(" ");
				let target = el.getAttribute("target-view");
				target = $(target);
				target = target[0] ? target : $(el);
				classNames.forEach(className => {
					if (!isIntersecting && entry.boundingClientRect.width === 0 && entry.boundingClientRect.height === 0) {
						if (className.startsWith("-")) {
							target.removeClass(className.substring(1));
						}
						else {
							target.addClass(className);
						}
						const callbackStr = el.getAttribute(`on-${attrHiddenNotIn}`);
						callbackStr && (new Function(callbackStr))();
					}
				});
			}
		});

	});

	$(`[${attrIn}], [${attrNotIn}], [${attrHiddenNotIn}]`).toArray().forEach(el => {
		intersectionObserver.observe(el);
	});

	let viewport = getViewport();

	const parallaxPositionType = {
		"top": 0,
		"center": 50,
		"bottom": 100,
	};
	const $parallax = $(`[parallax]`).toArray().map(el => {
		const $el = $(el);
		const move = +(el.getAttribute("parallax-move") || 0);
		const speed = +(el.getAttribute("parallax-speed") || 1);
		const position = el.getAttribute("parallax-position");
		const offset = +(el.getAttribute("parallax-offset") || 0);
		const tag = $(el).prop("tagName").toLowerCase();
		const _position = $(el).css("position");
		$el.css("position", "relative");
		const display = $el.css("display");
		console.log("_position", _position, $el.attr("style"));
		$el.css("position", ($el.attr("style") || "").includes("position") ? _position : "");

		return {
			el,
			speed,
			move,
			position: +(parallaxPositionType[position] || position || 0) / 100,
			offset,
			shift: 0,
			wrapper: $(el).wrap(`<${tag} style="display: ${display}; min-width: 1px; min-height: 1px;" class="parallax-wrapper"></${tag}>`).parent()[0],
		};
	});

	function updateGeometry () {
		viewport = getViewport();
	}

	setInterval(updateGeometry, 2000);
	$(window).on("resize", updateGeometry);
	function updateParallax () {
		if (!app.isMobile) {
			$parallax.forEach(p => {
				const rect = cloneRect(p.wrapper.getBoundingClientRect());
				// console.log("rect", rect);
				if (!rect.width && !rect.height) {
					return;
				}
				const position = p.position * viewport.bottom;
				let val = 0;
				if (rect.top - position < 0) {
					val = -(rect.top - position) * Math.sign(p.move);
				}
				const shift = Math.round(lineLimit(val, {x1: 0, y1: 0, x2: p.move / p.speed, y2: p.move}) + p.offset);
				if (shift != p.shift) {
					p.active = true;
					p.shift = shift;
					$(p.el).css("transform", `translateY(${shift}px)`);
				}
			});
		}
		else {
			$parallax.forEach(p => {
				if (p.active) {
					p.active = false;
					$(p.el).css("transform", "");
				}
			});
		}
	}

	updateGeometry();
	updateParallax();
	window.addEventListener("scroll", () => requestAnimationFrame(updateParallax), {passive: true});
}
