try {
	class FocusLimiter extends HTMLElement {
		static get observedAttributes () {
			return ["active"];
		}
		constructor () {
			super();
			try {
				if (!FocusLimiter.contentTpl) {
					FocusLimiter.contentTpl = document.createElement("template");
					FocusLimiter.contentTpl.innerHTML = `<style>:host {display: block;} :host .first, :host .last {position: fixed; z-index: -1; pointer-events: none; top: 50%; left: 50%;}</style>
					<div class="first" tabindex="0"></div><slot></slot><div tabindex="0" class="last"></div>`;
				}
				this.unregister = [];
				if (!this.shadowRoot) {
					this.attachShadow({mode: "open"});
					this.root = this.shadowRoot;
					this.root.appendChild(FocusLimiter.contentTpl.content.cloneNode(true));
					this.first = this.root.querySelector(".first");
					this.slotEl = this.root.querySelector("slot");
					this.last = this.root.querySelector(".last");
				}
			}
			catch {}
		}
		get active () {
			return this._active;
		}
		set active (value) {
			this._active = !!value;
			this.toggleAttribute("active", this._active);
		}
		connectedCallback () {
			let ignore;
			const focusin = event => {

				if (ignore || !this.active || (!this.offsetWidth && !this.offsetHeight)) {
					return;
				}
				// console.log("focusin", event.target, event.relatedTarget);

				const limiters = [...this.slotEl.assignedElements()];
				let backward = false;

				if (limiters.length) {
					const limiter = event.target && limiters.find(limiter => event.target === limiter || limiter.contains(event.target));
					const prevLimiter = event.relatedTarget && limiters.find(limiter => event.relatedTarget === limiter || limiter.contains(event.relatedTarget));

					const target = limiter ? this.slotEl : (event.target === this ? this.root.activeElement : event.target);
					const relatedTarget = prevLimiter ? this.slotEl : event.relatedTarget;

					if (target && relatedTarget) {
						if (target.compareDocumentPosition(relatedTarget) & Node.DOCUMENT_POSITION_FOLLOWING) {
							backward = true;
						}
					}

					if (!limiter) {
						const elements = limiters.reduce((res, el) => {
							return [...res, el, ...el.querySelectorAll("*")];
						}, []).filter(el => el.getAttribute("tabindex") !== "-1");

						elements
							.sort((a, b) => {
								const tabindexA = (+a.getAttribute("tabindex") || 0);
								const tabindexB = (+b.getAttribute("tabindex") || 0);
								if (tabindexA && !tabindexB) {
									return -1;
								}
								if (!tabindexA && tabindexB) {
									return 1;
								}
								return tabindexA - tabindexB;
							});
						if (backward) {
							elements.reverse();
						}

						elements
							.some(element => {
								element.focus();
								console.log("FOCUS", element);
								return document.activeElement === element;
							});
					}
				}
			};
			this.unregister.push(() => {
				document.removeEventListener("focusin", focusin);
			});
			document.addEventListener("focusin", focusin);
			if (!this._initialized) {
				this._initialized = true;
				this._active = this.hasAttribute("active");
				ignore = true;
				this.first.focus();
				ignore = false;
			}
		}
		disconnectedCallback () {
			let callback;
			while (callback = this.unregister.pop()) {
				callback();
			}
		}
		attributeChangedCallback (attrName, oldVal, newVal) {
			if (attrName === "active") {
				this._active = this.hasAttribute("active");
			}
		}
	}

	customElements.define("x-focus-limiter", FocusLimiter);

}
catch {}
