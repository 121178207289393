import "./public-path.mjs";
import "intersection-observer"; // polyfill
import $ from "cash-dom";
import cookie from "cookie-machine";
import {appearHeight, toBoolean, urlParams, handleResponse, scrollIntoView} from "app/common/utils.mjs";
import {initImgLazyLoad} from "app/common/scroll.mjs";
import EventEmitter from "eventemitter3";
import gtm from "app/common/gtm.mjs";
import "app/common/focus-limiter";
// import "focus-visible";

"webpackPublicPath";

class App extends EventEmitter {
	constructor () {
		super();
		this.settings = {};
	}
	finishLoad () {
		const finish = () => {
			console.log("finish");
			window.styleLoaded = true;
			$("html, body").removeClass("page-loading");
			setTimeout(() => {
				$(".page-loader-screen").css({"display": "none"});
				if (!app.isCookieAccepted()) {
					if ($(".cookie-consent-line").is("dialog")) {
						$(".cookie-consent-line").attr("open", "");
					}
					$(".cookie-consent-line").addClass("m-open");
					appearHeight(".cookie-consent-line", "show");
				}
				$(".overscroll-fill--el").removeClass("overscroll-fill--pending").addClass("overscroll-fill");
				setTimeout(() => {
					this.emit("finish-load");
				});
			}, 1300);
		}
		if (window.styleLoaded) {
			finish();
		}
		else {
			window.onStyleLoaded = finish;
		}
	}
	isCookieAccepted () {
		return toBoolean(cookie.get("allowCookies"));
	}
	getUTM () {
		return Object.fromEntries([...(new URL(window.location.href)).searchParams.entries()].filter(([key]) => key.startsWith("utm_")));
	}
	async storeUTM () {
		const utmParams = app.getUTM();
		if (Object.keys(utmParams).length) {
			try {
				const response = await fetch(`api/utm/save${urlParams(utmParams)}`, {
					method: "POST"
				})
					.then(handleResponse);
			}
			catch (error) {

			}
		}
	}
	async initCommon () {
		$(document).on("cookie-accept", async () => {
			await app.storeUTM();
			if (gtm) {
				gtm.push({event: "allowCookiesChange"});
			}
		});

		[...$("video")].forEach(video => {
			video.setAttribute("disableRemotePlayback", "");
		});

		function updateViewportSize () {
			const width = $(window).width();
			app.isDesktop = width >= 1139;
			app.isMobile = width <= 767;
			app.isTablet = width <= 1139 && width > 767;
		}

		let focusType;
		let focusTypeTime;
		window._setFocusType = (type) => {
			focusType = type;
			// console.log("set focus type", type);
			clearTimeout(focusTypeTime);
			focusTypeTime = setTimeout(() => {
				focusType = null;
			}, 100);
		};
		$(document)
			.on("mousedown touchstart pointerdown", () => {
				window._setFocusType("pointer");
				$("body").attr("data-focus-type", "pointer");
			})
			.on("keydown", () => {
				window._setFocusType("visible");
			});

		document.addEventListener("focusin", event => {
			if (!event.relatedTarget) {
				setTimeout(() => {
					$("body").attr("data-focus-type", focusType || "visible");
				}, 30);
			}
			else {
				$("body").attr("data-focus-type", focusType);
			}

			if (focusType === "visible") {
				scrollIntoView(event.target);
			}
		});

		let restm;
		window.addEventListener("resize", event => {
			updateViewportSize();
			toggleMenu(false);
			clearTimeout(restm);
			restm = setTimeout(() => {
				updateHeaderScroll(true);
				updateOverscroll();
			}, 30);
			this.emit("resize");
		});
		updateViewportSize();

		$(".cookie-consent-line").css("display", "none");

		const $overscroll = $(".overscroll-fill--el");
		function updateOverscroll () {
			if (window.pageYOffset > 0) {
				if (!$overscroll.is(".overscroll-fill-bottom")) {
					$overscroll.addClass("overscroll-fill-bottom");
				}
			}
			else {
				if ($overscroll.is(".overscroll-fill-bottom")) {
					$overscroll.removeClass("overscroll-fill-bottom");
				}
			}
		}

		const $pageHeader = $(".page-header");
		const pageHeader = $pageHeader[0];
		const $pageHeaderPlaceholder = $(".page-header-placeholder");
		let restoreFocus;
		const onesc = event => {
			if (event.key === "Escape") {
				window._setFocusType("visible");
				toggleMenu(false);
			}
		};
		function toggleMenu (state) {
			const pageYOffset = window.pageYOffset;
			if (!state) {
				if (!$pageHeader.is(".page-header-sticky-pos")) {
					$pageHeaderPlaceholder.css("height", ``);
				}
				$(".header-menu-button").attr({
					"title": "Open site menu",
					"aria-label": "Open site menu",
					"aria-expanded": "false",
				});
				$pageHeader.closest("x-focus-limiter").removeAttr("active");
				window.removeEventListener("keydown", onesc, true);
				if (restoreFocus) {
					restoreFocus.focus();
				}
				$pageHeader.toggleClass("m-opened", state);
				$pageHeader.find(".header-menu-button-content").toggleClass("m-close", state);
			}
			else {
				const topPos = $pageHeaderPlaceholder[0].getBoundingClientRect().top + pageYOffset;
				const scrollForward = (topPos > 0 && pageYOffset < topPos);
				if (scrollForward) {
					window.scrollTo(0, topPos);
				}
				setTimeout(() => {
					const headerRect = pageHeader.getBoundingClientRect();
					$pageHeaderPlaceholder.css("height", `${headerRect.height}px`);
					restoreFocus = document.activeElement;
					$(".header-menu-button").attr({
						"title": "Close site menu",
						"aria-label": "Close site menu",
						"aria-expanded": "true",
					});
					$pageHeader.closest("x-focus-limiter").attr("active", "");

					window.addEventListener("keydown", onesc, true);

					$pageHeader.toggleClass("m-opened", state);
					$pageHeader.find(".header-menu-button-content").toggleClass("m-close", state);
				}, scrollForward ? 100 : 0);
			}

		}

		$(".header-menu-button").on("click", event => {
			toggleMenu(!$pageHeader.is(".m-opened"));
		});

		const lastPageYOffset = [window.pageYOffset] ;
		let tm;
		function updateHeaderScroll (force = false) {
			const pageYOffset = window.pageYOffset;

			if (pageYOffset !== lastPageYOffset[0] || force) {
				const headerRect = pageHeader.getBoundingClientRect();
				// $pageHeader.removeClass("m-opened");
				// $pageHeader.find(".header-menu-button-content").removeClass("m-close");
				if (!$pageHeader.hasClass("m-opened")) {

					const topPos = $pageHeaderPlaceholder[0].getBoundingClientRect().top + pageYOffset;
					if (pageYOffset > topPos) {
						if (lastPageYOffset[0] > pageYOffset || force) {
							tm && clearTimeout(tm);
							tm = null;
							$pageHeader.addClass("page-header-sticky-pos page-header-sticky-bg");
							$pageHeader.removeClass("page-header-sticky-hidden");
							$pageHeaderPlaceholder.css("height", `${headerRect.height}px`);
						}
						else { // scrolling to bottom

							if (pageYOffset > headerRect.height) {
								$pageHeader.addClass("page-header-sticky-hidden");
							}
							if (pageYOffset > headerRect.height || pageYOffset === topPos) {
								if (!tm) {
									tm = setTimeout(() => {
										$pageHeader.removeClass("page-header-sticky-pos");
										$pageHeaderPlaceholder.css("height", ``);
										tm = null;
									}, 30);
								}
								$pageHeader.removeClass("page-header-sticky-bg");
							}
						}
					}
					else {
						if (!tm) {
							tm = setTimeout(() => {
								$pageHeader.removeClass("page-header-sticky-pos");
								$pageHeaderPlaceholder.css("height", ``);
								tm = null;
							}, 30);
						}
						$pageHeader.removeClass("page-header-sticky-hidden");
						$pageHeader.removeClass("page-header-sticky-bg");
					}
				}
			}
			lastPageYOffset[0] = pageYOffset;
			// if (pageYOffset !== lastPageYOffset[0]) {
			// 	setTimeout(() => {
			// 		lastPageYOffset.pop();
			// 	}, 200);
			// 	lastPageYOffset.unshift(pageYOffset);
			// }
			// if (repeat) {
			// 	setTimeout(() => updateHeaderScroll(true), 500);
			// }
		}

		$(`.store-badges-item.google-play-badge`).on("click", event => {
			gtm.push({event: `google-play-badge-click`});
		});

		$(`.store-badges-item.app-store-badge`).on("click", event => {
			gtm.push({event: `app-store-badge-click`});
		});


		window.addEventListener("scroll", event => {

			updateHeaderScroll();
			updateOverscroll();
		}, {passive: true});


		$(".cookie-consent-line form").on("submit", event => {
			event.preventDefault();
			appearHeight(".cookie-consent-line", "hide");
			if ($(".cookie-consent-line").is("dialog")) {
				$(".cookie-consent-line").removeAttr("open");
			}
			$(".cookie-consent-line").removeClass("m-open");
			const expires = new Date();
			expires.setFullYear(expires.getFullYear() + 1);
			cookie.set("allowCookies", "true", {expires});
			$(document).trigger("cookie-accept");
			// $(".cookie-consent-line").remove();
		});

		$(document).on("click", `a[href="#cookie-policy-link"]`, event => {
			event.preventDefault();
			if ($(".cookie-consent-line").is(".m-open")) {
				window.location.href = "/legal-info#cookie-policy";
			}
			else {
				appearHeight(".cookie-consent-line", "show");
			}
		});
		initImgLazyLoad();
	}
}

const app = new App;
export default app;

async function main (page, settings) {
	app.settings = Object.assign(app.settings, settings);
	// await common.preinit(page);
	let ctrl;
	try {
		ctrl = await (import(`./page/${page}`).then(res => res.default));
	}
	catch (error) {
		console.log(`no controller for "${page}" page`);
		console.log(error);
	}
	if (ctrl) {
		await Promise.resolve(ctrl());
	}

	await app.initCommon();

	// await common.postinit(page, ctrl);
	app.finishLoad();
}

window.initMain(main);

